import React, { useState } from "react";
import "./DesignBlock.css";
import design_Block from "../../../images/designBlock.png";
import link from "../../../images/link.svg";
import edit from "../../../images/edit.svg";
import loading from "../../../images/loading.svg";
import cart_image from "../../../images/cart_image.svg";
import bookmark from "../../../images/bookmark.svg";
import { useNavigate } from "react-router-dom";
import templates from "../../../static/templates.json";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip } from "react-tooltip";
import axios from "axios";
import { InputBase, Box } from "@mui/material";
import { useDebounce } from "../../../hooks/useDebounce";

function DesignBlock({ data, setActiveStep, disableDelete, handleOpenModal, getOrders }) {
  const navigate = useNavigate();

  const [duplicateLoading, setDuplicateLoading] = useState(false);
  const templateData = templates.find((e) => e.id === data.DesignId);

  const designUserName = data?.DesignUserName || templates?.find((e) => e?.id === data?.DesignId)?.name;
  const [designName, setDesignName] = useState(designUserName);

  const sendData = (value) => {
    const orderData = {
      ...data,
      DesignUserName: value,
    };
    const formData = new FormData();

    formData.append("data", JSON.stringify(orderData));

    const token = localStorage.getItem("token");

    const config = {
      method: "POST",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL || "https://labelmaker.traceaudioserver.com"}/api/v1/editDesign`,
      data: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    axios
      .request(config)
      .then((response) => {
        console.log(response);
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const debouncedHandleDesignNameChange = useDebounce((value) => {
    sendData(value);
  }, 2000);

  const handleNameChange = (value) => {
    setDesignName(value);
    debouncedHandleDesignNameChange(value);
  };

  function orderItem() {
    const newData = [
      {
        settings: data.DesignData[0].settings,
        logotype:
          data.DesignLogotype === null
            ? ""
            : data.DesignLogotype.includes(process.env.REACT_APP_API_URL || "https://labelmaker.traceaudioserver.com")
            ? `${data.DesignLogotype}`
            : `${process.env.REACT_APP_API_URL || "https://labelmaker.traceaudioserver.com"}${data.DesignLogotype}`,
        amount: Number(data.Amount.replaceAll('"', "")),
        data: data.DesignData[0].data,
        groups: data.DesignData[0].groups,
        isEditing: true,
        Id: data.Id,
      },
    ];

    window.sessionStorage.setItem("data", JSON.stringify(newData));
    window.sessionStorage.setItem("template", JSON.stringify(templateData));
    window.sessionStorage.setItem("step", JSON.stringify(4));
    window.sessionStorage.setItem("savedActiveStep", 5);
    setActiveStep(4);
    navigate("/");
  }

  const edititem = () => {
    const newData = [
      {
        settings: data.DesignData[0].settings,
        logotype:
          data.DesignLogotype === null
            ? ""
            : data.DesignLogotype.includes(process.env.REACT_APP_API_URL || "https://labelmaker.traceaudioserver.com")
            ? `${data.DesignLogotype}`
            : `${process.env.REACT_APP_API_URL || "https://labelmaker.traceaudioserver.com"}${data.DesignLogotype}`,
        amount: Number(data.Amount.replaceAll('"', "")),
        data: data.DesignData[0].data,
        groups: data.DesignData[0].groups,
        isEditing: true,
        Id: data.Id,
      },
    ];

    window.sessionStorage.setItem("data", JSON.stringify(newData));
    window.sessionStorage.setItem("template", JSON.stringify(templateData));
    window.sessionStorage.setItem("step", JSON.stringify(1));
    setActiveStep(1);
    navigate("/");
  };

  const duplicateItem = () => {
    const orderData = {
      DesignName: templateData.name,
      DesignId: templateData.id,
      DesignLogotype: data.DesignLogotype || null,
      DesignData: [
        {
          data: data.DesignData[0].data.map((item) => {
            return item;
          }),
          groups: data.DesignData[0].groups,
          settings: data.DesignData[0].settings,
        },
      ],
      DesignDescription: templateData.DesignDescription,
      Amount: data.Amount,
      Status: 1,
      Id: null,
    };
    const formData = new FormData();

    if (data.DesignLogotype && !data.DesignLogotype?.includes("http")) {
      formData.append("file", data.DesignLogotype);
    }

    formData.append("data", JSON.stringify(orderData));

    const token = localStorage.getItem("token");

    const config = {
      method: "POST",
      maxBodyLength: Infinity,
      url: `${process.env.REACT_APP_API_URL || "https://labelmaker.traceaudioserver.com/api/v1/addDesign"}`,
      data: formData,
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };

    setDuplicateLoading(true);
    axios
      .request(config)
      .then((response) => {
        if (response.data.designId) {
          getOrders();
          setDuplicateLoading(false);
        }
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setDuplicateLoading(false);
      });
  };

  return (
    <div className="designBlock" style={{ overflowX: "hidden" }}>
      <Box
        sx={{
          marginY: "6px",
        }}
      >
        <InputBase fullWidth value={designName} onChange={(e) => handleNameChange(e.target.value)} />
      </Box>
      <img
        onClick={edititem}
        style={{ width: "105%", marginLeft: "-5.5%" }}
        src={
          data.ModelImage !== null
            ? process.env.REACT_APP_API_URL || "https://labelmaker.traceaudioserver.com" + `${data.ModelImage}`
            : design_Block
        }
        alt="design block"
      />
      <div className="designBlockBottom">
        <button
          data-tooltip-id={`my-tooltip-${data.DesignId}`}
          data-tooltip-content="Copied!"
          onClick={(e) => {
            navigator.clipboard.writeText(window.location.origin + "/preview/" + data.Id);
          }}
        >
          <img src={link} alt="Link" />
        </button>
        {!disableDelete && (
          <button onClick={() => handleOpenModal(data.Id)}>
            <p>Delete</p>
          </button>
        )}
        <button onClick={edititem}>
          <img src={edit} alt="Edit" />
          <p>Edit</p>
        </button>
        <button onClick={duplicateItem}>
          <img src={duplicateLoading ? loading : bookmark} alt="Duplicate" style={{ width: "20px", height: "20px" }} />
          <p>Duplicate</p>
        </button>
        <button onClick={orderItem}>
          <img src={cart_image} alt="Cart" />
          <p>Order</p>
        </button>
      </div>
      <Tooltip id={`my-tooltip-${data.DesignId}`} openOnClick={true} closeOnScroll closeOnEsc />
    </div>
  );
}

export default DesignBlock;
