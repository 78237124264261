import MainRouter from "./router";
import "./App.css";
import { useEffect } from "react";

function App() {
  useEffect(() => {
    console.log("1.75");
  }, []);

  return (
    <div className="App">
      <MainRouter />
    </div>
  );
}

export default App;
