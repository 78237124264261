import React, { useEffect } from "react";
import "./MyDesigns.css";
import plus_butoon from "../../../images/plus_button.svg";
import DesignBlock from "./DesignBlock";
import { Link } from "react-router-dom";

function MyDesigns({ designData, setDesignData, setActiveStep, setData, setActivePage, deleteItem, handleOpenModal, getOrders }) {
  useEffect(() => {
    setActivePage("mydesigns");
  }, []);

  return (
    <div className="myDesigns">
      <div className="myDesignsHeader">
        <p>My Designs</p>
        <Link to="/">
          <img src={plus_butoon} style={{ marginLeft: "6px" }} alt="Plus" onClick={(e) => setActiveStep(0)} />
        </Link>
      </div>
      {designData.length > 0 ? (
        designData.map((design, index) => (
          <DesignBlock
            key={index}
            handleOpenModal={handleOpenModal}
            deleteItem={deleteItem}
            data={design}
            designData={designData}
            setDesignData={setDesignData}
            setActiveStep={setActiveStep}
            setData={setData}
            getOrders={getOrders}
          />
        ))
      ) : (
        <p className="noDesigns">No designs found</p>
      )}
    </div>
  );
}

export default MyDesigns;
